import { Row, Typography, Col, Button, Spin, Empty } from "antd";
import { getRounds } from "./network_requests";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";

import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

import tickicon from "../../../assets/Icons_waste/task_finished.png";
import EditCheckPoint from "./editCheckPoint";
import Scan from "./scan";

const { Text } = Typography;

export default function CheckDetail(props) {
  const { check, setCheck } = props;
  const [rounds, setRounds] = useState([]);
  const [scan, setScan] = useState(false);
  const [nfcscan, setNfcscan] = useState(null);
  // const [addRound, setAddRound] = useState(false);
  // const [editRound, setEditRound] = useState(null);
  const [editCheck, setEditCheck] = useState(null);

  const [updateData, setUpdateData] = useState(false);

  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchRounds = async () => {
      document.body.style.zoom = "100%";
      setLoad(true);

      const Round_ = await getRounds(check?.stkey);

      if (Round_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setRounds(Round_ ? Round_ : []);
      setLoad(false);
    };

    fetchRounds();
  }, [updateData]);

  useEffect(() => {
    if (nfcscan) {
      setEditCheck({
        roundStKey: check?.stkey,
        nfcTagObject: nfcscan,
      });
    }
  }, [nfcscan]);

  return (
    <>
      {editCheck ? (
        <EditCheckPoint
          checkPoint={editCheck}
          setCheckPoint={setEditCheck}
          setNfcscan={setNfcscan}
          load={load}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
        />
      ) : scan ? (
        <Scan
          checkPoint={check}
          setActive={setScan}
          setMessage={setNfcscan}
          update={updateData}
          setUpdate={setUpdateData}
        />
      ) : (
        <div
          style={{
            height: "95vh",
            backgroundColor: "#f3f7ff",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#038fde",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCheck(null);
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                {check?.cfeld1}
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#f3f7ff",
                left: 0,
                padding: "20px",
                width: "100%",
              }}
            >
              <Row style={{ padding: "0px 20px 0px 20px" }}>
                <Text style={{ color: "gray", textTransform: "uppercase" }}>
                  check points
                </Text>
              </Row>
            </div>
          </>

          {load ? (
            <div
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Spin />
            </div>
          ) : null}
          {!load ? (
            <>
              {rounds?.length === 0 ? <Empty /> : null}

              {[...rounds]?.map((round) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      // borderBottom: "1px solid #eaeaea",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={21}
                      style={{
                        padding: 0,
                      }}
                      onClick={() => {
                        // setEditRound(round);
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditCheck(round);
                        }}
                      >
                        {round?.objcfeld1}
                      </Text>
                    </Col>

                    <Col
                      xs={1}
                      style={{
                        display: round?.erledigtDatum ? "flex" : "none",
                        justifyContent: "flex-end",
                        padding: 0,
                      }}
                    >
                      <img src={tickicon} height={"16px"} />
                    </Col>

                    <Col
                      xs={round?.erledigtDatum ? 2 : 3}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 0,
                        cursor: "pointer",
                      }}
                    >
                      <RightOutlined
                        size="large"
                        onClick={() => {
                          setEditCheck(round);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}

              <Row
                justify={"center"}
                style={{
                  width: "100%",
                  marginTop: "10vh",
                  paddingBottom: "30px",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setScan(!scan);
                  }}
                >
                  Scan NFC
                </Button>
              </Row>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
