import { Input, Form, Typography, Button, Row, Col, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { checkTagID, getLatestPhoto } from "./network_requests";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Documents from "./documents";

export default function EditCheckPoint(props) {
  const { checkPoint, setCheckPoint, setNfcscan } = props;

  const [photoActive, setPhotoActive] = useState(false);
  const [latestPhoto, setLatestPhoto] = useState(null);

  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    const fetchPhoto = async () => {
      if (!photoActive) {
        const photo = await getLatestPhoto(checkPoint?.objstkey);
        setLatestPhoto(photo);
      }
    };

    if (checkPoint?.objstkey) {
      fetchPhoto();
    }
  }, [checkPoint?.roundStKey, photoActive]);

  return (
    <>
      {photoActive ? (
        <Documents
          templateKey={checkPoint?.objstkey}
          setActive={setPhotoActive}
        />
      ) : (
        <div
          style={{
            backgroundColor: "#f3f7ff",
            height: "85vh",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#038fde",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setNfcscan(null);
                  setCheckPoint(null);
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                {checkPoint?.objcfeld1}
              </Typography>
            </div>
          </>
          <Form
            name="edit CheckPoint"
            ref={ref}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              padding: "10px 30px",
            }}
            initialValues={{
              bemerkung: checkPoint?.erledigtBemerkung,
            }}
            onFinish={async (values) => {
              setNfcscan(null);
              setCheckPoint(null);
              props.setLoad(true);
              const insertData = checkPoint?.nfcTagObject
                ? { ...checkPoint }
                : {
                    roundStKey: checkPoint?.roundStKey,
                  };
              const response = await checkTagID({
                ...insertData,
                ...values,
              });

              if (response.status === 401) {
                localStorage.removeItem("user_id");
                history.go(0);
              }

              props.setUpdate(!props.update);
            }}
            //   onFinishFailed={onFinishFailed}
            //   autoComplete="off"
          >
            <Form.Item style={{ marginBottom: "20px" }}>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                Check the {checkPoint?.objcfeld1} and test if everything is
                functional!
              </Typography>
            </Form.Item>
            <Form.Item
              label={"Comment Check"}
              name="bemerkung"
              style={{ marginBottom: 0 }}
            >
              <TextArea rows={6} />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px", marginTop: "20px" }}
              label={"Foto"}
            >
              <Row
                style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#fff",
                  border: "1px solid #f5f5f5",
                  margin: "0px",
                  padding: "15px",
                }}
              >
                <Row style={{ width: "100%", margin: 0, padding: "20px" }}>
                  <Row
                    style={{
                      backgroundImage: `url(${latestPhoto?.file.replace(
                        "application/pdf",
                        "image/jpeg"
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "100%",
                      margin: 0,
                    }}
                  ></Row>
                </Row>
              </Row>
            </Form.Item>

            <Row
              style={{
                marginTop: "16px",

                margin: 0,
                padding: 0,
              }}
            >
              <Col
                xs={10}
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                      display: checkPoint?.nfcTagObject ? "none" : "",
                    }}
                    onClick={() => {
                      setPhotoActive(!photoActive);
                    }}
                  >
                    Photos
                  </Button>
                </Form.Item>
              </Col>
              <Col
                xs={4}
                style={{
                  padding: 0,
                  margin: 0,
                }}
              />
              <Col
                xs={10}
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}{" "}
    </>
  );
}
