import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

const Scan = ({ update, setUpdate, setActive, setMessage, onSuccess }) => {
  const [status, setStatus] = useState("Pending");
  const history = useHistory();
  const [tagScaneed, setTagScanned] = useState("");

  const scan = useCallback(async () => {
    if ("NDEFReader" in window) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        console.log("Scan started successfully.");
        ndef.onreadingerror = () => {
          setStatus("Scanned Failed");
          console.log("Cannot read data from the NFC tag. Try another one?");
        };

        ndef.onreading = (event) => {
          console.log("NDEF message read.");
          onReading(event);
        };
      } catch (error) {
        setStatus("Scanned Failed");

        console.log(`Error! Scan failed to start: ${error}.`);
      }
    } else {
      setStatus("Device Unsupported");
    }
  }, []);

  const onReading = async ({ message, serialNumber }) => {
    let found = false;
    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          const textDecoder = new TextDecoder(record.encoding);
          const tag = textDecoder.decode(record.data);
          setTagScanned(tag);
          break;
        case "url":
          // TODO: Read URL record with record data.
          break;
        default:
        // TODO: Handle other records with record data.
      }
    }
    if (found) {
      setStatus("Scanned Successful");
    } else {
      setStatus("Scanned Failed");
    }
  };

  useEffect(() => {
    scan();
  }, [scan]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
          onClick={() => {
            setActive(false);
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "22px", fontWeight: "bold" }} />
        </Typography>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 450,
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          {status === "Scanned Successful"
            ? "Successful"
            : status === "Pending"
            ? "Pending"
            : "Failed"}{" "}
          Scan
        </Typography>
      </div>
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f3f7ff",
        }}
      >
        <div
          style={{
            height: "300px",
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid black",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={() => {
            if (status === "Scanned Successful") {
              setMessage(tagScaneed);
            } else {
              setActive(false);
              setUpdate(!update);
            }
          }}
        >
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: 400,
            }}
          >
            {status}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Scan;
